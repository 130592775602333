import React from 'react';
import { FaLeaf, FaHandshake, FaAccessibleIcon } from 'react-icons/fa'; // Import icons from react-icons

const VisionMission = () => {
    const sections = [
        {
            title: "Our Mission & Vision",
            icon: <FaLeaf className="text-colorGreen" />,
            text: `At Kala Interiors, our mission is to transform spaces into beautiful, functional, and personalized environments that reflect the unique tastes and lifestyles of our clients. We are committed to delivering exceptional interior design solutions with a focus on quality, creativity, and customer satisfaction. Our vision is to be a leading name in the interior design industry, renowned for our innovative designs and dedication to creating spaces that inspire and enrich the lives of those who inhabit them.`,
            image: "/images/mission and vision.webp",
        },
        {
            title: "Our Values",
            icon: <FaHandshake className="text-colorGreen" />,
            text: `We believe in the importance of understanding our clients' needs and exceeding their expectations through our commitment to integrity, transparency, and excellence. Our values are centered around collaboration, creativity, and sustainability. We strive to create designs that not only enhance the aesthetic appeal of a space but also ensure functionality and sustainability for the long-term benefit of our clients and the environment.`,
            image: "/images/Our Values.webp",
        },
        {
            title: "Sustainability & Accessibility",
            icon: <FaAccessibleIcon className="text-colorGreen" />,
            text: `Sustainability is at the heart of our design philosophy. We are dedicated to incorporating eco-friendly materials and practices that reduce environmental impact and promote energy efficiency. Accessibility is also a key consideration in our designs, ensuring that all spaces are inclusive and accommodate the needs of all users, regardless of age or ability. Our goal is to create interiors that are not only beautiful and functional but also contribute positively to our planet and community.`,
            image: "/images/sustainability and accessibility.webp",
        },
    ];

    return (
        <div className="bg-gray-50 px-4 py-12">
            <div className="space-y-16 mx-auto max-w-6xl">
                {sections.map((section, index) => (
                    <div
                        key={index}
                        className={`flex flex-col md:flex-row items-center gap-12 ${index % 2 === 0 ? "" : "md:flex-row-reverse"
                            }`}
                    >
                        {/* Image */}
                        <div className="flex-shrink-0 border-2 border-gray-200 shadow-xl rounded-xl w-full md:w-[350px] h-[350px] overflow-hidden">
                            <img
                                src={section.image}
                                alt={section.title}
                                className="w-full h-full object-cover"
                            />
                        </div>

                        {/* Text */}
                        <div className="flex-1 space-y-4 text-center md:text-left">
                            <h2 className="flex justify-center md:justify-start items-center gap-3 font-bold font-dancingScript text-3xl text-colorBlue sm:text-4xl md:text-5xl">
                                {section.icon}
                                {section.title}
                            </h2>
                            <p className="font-dancingScript text-base text-gray-700 text-justify sm:text-lg md:text-3xl leading-relaxed">
                                {section.text}
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>

    );
};

export default VisionMission;
