import React from 'react';
import { FaFacebook, FaLinkedin, FaYoutube, FaWhatsapp } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";


export default function Component() {
  const socialLinks = {
    linkedin: 'https://www.linkedin.com/in/kala-interior-a3a088333?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    facebook: 'https://www.facebook.com/',
    twitter: 'https://twitter.com/',
    youtube: 'https://www.youtube.com/',
    whatsapp1: 'https://wa.me/+918378989511',
    whatsapp2: 'https://wa.me/+918378989507',
  };

  const openLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="bg-gradient-to-r from-blue-100 via-green-100 to-blue-50 py-8">
      <div className="container mx-auto px-2 text-center">
        <h1 className="text-3xl sm:text-4xl lg:text-5xl font-extrabold text-colorBlue mb-3 font-dancingScript">Meet Our Creative Directors</h1>
        <h2 className="text-xl sm:text-2xl lg:text-3xl font-semibold text-colorGreen mb-4 font-dancingScript">Innovative & Futuristic</h2>

        <div className="relative ">
          <svg
            width="180"
            height="150"
            viewBox="0 0 171 129"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-32 h-24 lg:w-auto lg:h-auto transform rotate-180 mx-auto"
          >
            <path
              d="M1 128.374C24.2997 92.523 82.0713 29.9692 133.574 58.3837C168.736 77.7835 134.651 108.257 97.1699 85.7929C59.6885 63.3289 81.793 0.175376 170.113 16.2371M170.113 16.2371L156.325 26.2176M170.113 16.2371L153.636 1.31576"
              stroke="url(#paint0_linear_69_3690)"
              strokeLinecap="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_69_3690"
                x1="23.4948"
                y1="-13"
                x2="142.518"
                y2="149.951"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.488534" stopColor="#192C4B" />
                <stop offset="1" stopColor="#0D193A" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        <div className="bg-white rounded-2xl shadow-2xl overflow-hidden transform transition-all duration-300  max-w-6xl mx-auto">
          <div className="flex justify-center space-x-6 p-6">
            <img
              className="w-48 h-48 sm:w-56 sm:h-56 object-cover rounded-3xl shadow-lg transition-transform duration-300 transform hover:scale-105 border-4 border-white hover:border-blue-400"
              src="/amit-landge.jpg"
              alt="Amit Landge"
              loading="lazy"
            />
            <img
              className="w-48 h-48 sm:w-56 sm:h-56 object-cover rounded-3xl shadow-lg transition-transform duration-300 transform hover:scale-105 border-4 border-white hover:border-blue-400"
              src="/hema-landge.jpg"
              alt="Hema Amit Landge"
              loading="lazy"
            />
          </div>

          <div className="px-8 mb-4">
            <div className="font-dancingScript uppercase tracking-wide text-sm sm:text-base text-colorGreen   font-semibold text-center mb-4">
              Directors
            </div>
            <h3 className="font-dancingScript text-3xl sm:text-3xl lg:text-4xl font-extrabold text-colorBlue text-center leading-tight mb-4">
            Hema  & Amit  Landge
            </h3>
            <p className="mt-4 text-3xl text-gray-600 text-justify p-2 indent-8 font-dancingScript "   >
           Hema and  Amit   Landge are visionary leaders in the interior design industry. Together, they combine
              innovation and futuristic thinking to create breathtaking spaces that reflect both beauty and functionality.
            </p>
          </div>

          <div className="px-8  bg-gray-50 rounded-b-2xl">
            <p className="text-3xl text-gray-600 text-justify p-2 indent-8 font-dancingScript">
              With Amit's expertise in transforming spaces and Hema's keen eye for design trends, they lead Kala Interiors with a focus on creativity, sustainability, and client satisfaction. Their collaborative approach ensures that each project is not just a space, but a masterpiece that balances aesthetics with functionality.
            </p>
          </div>

          <div className="px-8 py-4 flex justify-center space-x-4  bg-gray-100 rounded-b-2xl">
            {Object.entries(socialLinks).map(([key, url]) => (
              <button
                key={key}
                onClick={() => openLink(url)}
                className="transition-all duration-300 p-2 rounded-full hover:bg-blue-50 shadow-lg transform hover:scale-110"
              >
                {/* Set icon color dynamically and make them smaller */}
                {key === 'linkedin' && (
                  <FaLinkedin size={24} className="text-blue-600 hover:text-blue-800" />
                )}
                {key === 'facebook' && (
                  <FaFacebook size={24} className="text-blue-700 hover:text-blue-900" />
                )}
                {key === 'twitter' && (
                  <FaSquareXTwitter size={24} className="text-blue-500 hover:text-blue-700" />
                )}
                {key === 'youtube' && (
                  <FaYoutube size={24} className="text-red-600 hover:text-red-800" />
                )}
                {key.startsWith('whatsapp') && (
                  <FaWhatsapp size={24} className="text-green-500 hover:text-green-700" />
                )}
              </button>
            ))}
          </div>

        </div>
      </div>
    </div>
  );
}
